/* SCSS */
import "../scss/vendor.scss";

/* JS */
// Babel Polyfills (Enable use of modern javascript)
import "core-js/stable";
import "regenerator-runtime/runtime";
// End of babel polyfills

import "bootstrap";

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
